import 'detect-autofill';
/// Called with:
// let el = docuement.querySelectorAll(YOUR_FORM);
// setLabelPositions(el)
//
const setLabelPositions = (el, options) => {
	const inputClass =		options && options.classSelector ? options.classSelector : undefined;
	const parentClass =		options && options.parentSelector ? options.parentSelector : '.form-group';
	const inputSelector =		inputClass !== undefined
		? `input${inputClass}, textarea${inputClass}`
		: 'input, textarea';
	const labelEvents = (input) => {
		document.addEventListener('onautocomplete', (e) => {
			const autocompletedInput = inputClass
				? e.path[0].classList.contains(inputClass)
				: e.path[0];
			const parent = autocompletedInput.closest(parentClass);
			if (parent) {
				parent.classList.add('active');
			}
		});

		const parent = input.closest(parentClass);
		input.addEventListener('focus', () => {
			if (parent != null) {
				parent.classList.add('active');
			}
		});
		input.addEventListener('blur', () => {
			if (input.value === '' && parent != null) {
				parent.classList.remove('active');
			}
		});
		if (input.value !== '') {
			if (parent != null) {
				parent.classList.add('active');
			}
		}
	};
	///
	// the only option expected at the moment is one that lets you be more specific
	// with which input you want to select
	// eg: input.form-input--styled
	// as opposed to all inputs
	const inputs = document.querySelectorAll(inputSelector);
	inputs.forEach((input) => {
		labelEvents(input);
	});
};

export default setLabelPositions;
