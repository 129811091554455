<template>
<div id="site-assesment" class="mb-lg relative">
	<SmoothReflow class="relative">
		<transition name="fade" mode="out-in">
			<FormulateForm
				v-if="!success"
				ref="form"
				key="form"
				class="w-full"
				@submit="handleSubmit"
			>
				<FormulateInput
					type="text"
					name="name"
					label="Name"
					validation="^required"
				/>
				<FormulateInput
					type="email"
					name="email"
					label="Email"
					validation="^required"
				/>
				<FormulateInput
					type="text"
					name="phone"
					label="Phone"
					validation="^required"
				/>
				<div class="form-group form-group--styled">
					<GoogleAutocomplete
						id="map"
						name="address"
						placeholder=""
						country="nz"
						@placechanged="getAddressData"
					/>
					<label for="map">
						Address
					</label>
				</div>
				<FormulateInput
					type="textarea"
					name="reason"
					label="Reason for Subdividing"
					validation="^required"
				/>
				<div class="recaptcha-blurb">
					<p>
						This site is protected by reCAPTCHA and the Google
						<a href="https://policies.google.com/privacy">Privacy Policy</a>
						and
						<a href="https://policies.google.com/terms">Terms of Service</a>
						apply.
					</p>
				</div>
				<button
					class="btn btn--primary uppercase mt-30"
					:class="{ loading: loading }"
					:disabled="loading"
				>
					Submit
				</button>
			</FormulateForm>
			<div v-if="success" key="thanks" class="w-full">
				<h1 class="h1">{{ successMessage }}</h1>
			</div>
		</transition>
	</SmoothReflow>
</div>
</template>

<script>
import axios from 'axios';
import GoogleAutocomplete from '~VModules/GoogleAutocomplete';
import SmoothReflow from '../components/SmoothReflow.vue';

export default {
	name: 'SiteAssessmentForm',
	components: {
		GoogleAutocomplete,
		SmoothReflow,
	},
	props: {
		successMessage: {
			type: String,
			default: "We'll be in touch soon",
		},
	},
	emits: [],
	data() {
		return {
			success: false,
			loading: false,
			expressForm: undefined,
		};
	},
	mounted() {
		this.expressForm = document.getElementById('express-site-assesment');
	},
	methods: {
		handleSubmit(event) {
			this.loading = true;
			if (!this.$store.actions.checkGscore()) {
				return false;
			}

			const formId = this.expressForm.formId.value;
			const formPayload = this.expressForm.formPayload.value;
			const data = new FormData(this.$refs.form.$el);
			data.append(window.csrfName, window.csrfToken);
			data.append('formId', formId);
			data.append('formPayload', formPayload);

			axios
				.post('/actions/express-forms/submit', data, {
					headers: {
						'Cache-Control': 'no-cache',
						'X-Requested-With': 'XMLHttpRequest',
						HTTP_X_REQUESTED_WITH: 'XMLHttpRequest',
					},
				})
				.then(response => response.data)
				.then((data) => {
					if (data.success) {
						this.success = true;
					} else if (data.errors) {
						this.error = data.errors;
					}
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getAddressData(addressData, placeResultData, id) {
			this.address = addressData;
		},
	},
};
</script>
