<template>
<div id="quetionnaire-subdivideTypes">
	<slot name="live" :set-subdivide-type="setSubdivideType" />
	<slot name="rent" :set-subdivide-type="setSubdivideType" />
	<slot name="sell" :set-subdivide-type="setSubdivideType" />
</div>
</template>

<script>
export default {
	name: 'SubdivideTypes',

	mounted() {
		window.sessionStorage.setItem('subdivide-type', 'live');
	},
	methods: {
		setSubdivideType(e) {
			const selected = e.currentTarget.dataset.id;
			console.log('click', selected);
			document.querySelectorAll('.questionnaire--option').forEach((item) => {
				item.classList.remove('active');
			});
			e.currentTarget.classList.add('active');
			window.sessionStorage.setItem('subdivide-type', selected);
		},
	},
};
</script>
