<template>
<div>
	<slot
		:address="address"
		:type="type"
		:lng="lng"
		:lat="lat"
	/>
</div>
</template>

<script>
export default {
	name: 'QuestionnaireValues',

	data() {
		return {
			success: false,
			address: '',
			type: '',
			lng: '',
			lat: '',
			loading: false,
		};
	},
	created() {
		this.address = window.sessionStorage.getItem('subdivide-address');
		this.type = window.sessionStorage.getItem('subdivide-type');
		this.lng = window.sessionStorage.getItem('subdivide-lng');
		this.lat = window.sessionStorage.getItem('subdivide-lat');

		if (this.address === undefined && window.href.includes('two')) {
			window.swup.loadPage({
				url: '/questionnaire/subdivide-questionnaire',
			});
		}
	},
};
</script>
