<template>
<div id="QuestionerRedirectModel" :class="{'__open': isPopupOpen}">
  <div class="__model-wrapper">
    <div class="model-title">
      <h1 class="h1 mb-md styled-heading">Welcome to <em>Subdivide</em></h1>
    </div>
    <div class="model-content">
      <p class="text-h5">What options do I have to subdivide my property ? <br/>Click the button below to unlock your free feasibility report.</p>
    </div>
    <div class="action-button">
      <a href="/questionnaire/subdivide-questionnaire" class="btn btn--primary max-w-none max-w-none whitespace-no-wrap">
        Request A Free Report
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
	name: 'QuestionerRedirectModel',
  data() {
    return {
      isPopupOpen: false,
    };
  },
	mounted() {
    window.addEventListener('scroll', this.displayRedirectionPopup);
	},

  methods: {
    displayRedirectionPopup () {
      // Show questioner redirect model
      const hasQuestionerDisplayed = window.localStorage.getItem('hasQuestionerDisplayed');
      if (!hasQuestionerDisplayed) {
        this.isPopupOpen = true;
        window.localStorage.setItem('hasQuestionerDisplayed', true);
        window.removeEventListener('scroll', this.displayRedirectionPopup);
      }
    },
  }
};
</script>

<style lang="scss">
#QuestionerRedirectModel {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 99;
  height: fit-content;
  max-width: 760px;
  opacity: 0;
  overflow: hidden;
  border-radius: 15px;
  margin: auto;
  box-shadow: 2px 7px 50px #00000059;
  display: none;

  &.__open {
    display: block;
    opacity: 1;
    transition: all 0.3s 0.2s ease-in-out;
  }

  .__model-wrapper {
    padding: 20px;
  }

  .model-title {
    text-align: center;
    padding-top: 30px;
  }

  .model-content {
    text-align: center;
  }

  .action-button {
    padding: 40px 0px 30px;
    text-align: center;
  }
}
</style>
