<template>
<div class="vue-dropdown" :class="{ isOpen: isOpen }">
	<div
		class="selected relative"
		:class="backgroundColor"
		@click="toggleIsOpen"
	>
		{{ selected ? selected.title : '' }}
		<div class="chevron-wrap center-y right-5">
			<svg class="chevron " viewBox="0 0 9 5" fill="none">
				<path d="M1 1L4.5 4L8 1" stroke="currentColor" />
			</svg>
		</div>
	</div>
	<ul
		ref="list"
		:class="{ isOpen: isOpen }"
		class="vue-dropdown__option-list"
	>
		<li
			v-for="(o, i) in options"
			:key="i"
			:class="{
				active: (selected && selected === o.handle) || selected === o,
			}"
			@click="handleClick(o)"
		>
			{{ o.title ? o.title : o }}
		</li>
	</ul>
</div>
</template>
<script>
import { slideDown, slideUp } from 'Utils/slide';

export default {
	local: {
		id: '#vue-dropdown',
	},
	props: {
		placeholder: {
			type: String,
		},
		bg: {
			type: String,
			default: 'transparent',
		},
		options: {
			type: Array,
			required: true,
		},
	},
	emits: ['selected'],
	data: () => ({
		selected: null,
		isOpen: false,
	}),
	computed: {
		backgroundColor() {
			return `bg-${this.bg}`;
		},
	},
	mounted() {
		[this.selected] = this.options;
	},
	methods: {
		handleClick(o) {
			this.selected = o;
			this.$emit('selected', o);
		},

		toggleIsOpen() {
			if (this.isOpen) {
				this.handleClose();
			} else {
				this.handleOpen();
			}
		},
		handleOpen() {
			// setTimeout(, 200);
			this.isOpen = true;
			slideDown(this.$refs.list, { duration: 400 }, () => {
				document.querySelector('body').addEventListener(
					'click',
					() => {
						this.handleClose();
					},
					{
						once: true,
					},
				);
			});
		},
		handleClose() {
			this.isOpen = false;
			slideUp(this.$refs.list);
		},
	},
};
</script>

<style scoped lang="scss">
.vue-dropdown {
	@apply font-basis  text-center;
	position: relative;
	z-index: 3;
	display: inline-block;
	min-width: 200px;
	transition: all 0.2s ease;

	.selected {
		z-index: 1;
		@apply text-white border-1 border-white text-h4;
		padding: 5px 20px 5px 5px;
	}
	ul {
		@apply border-1 border-t-0 border-white;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 0;
		width: 100%;
		box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.07);
		cursor: pointer;
		display: none;
	}

	li {
		@apply bg-navy text-h4 text-white text-center;
		padding: 5px 20px 5px 5px;
		transition: background 0.2s ease;
		&.active {
			@apply bg-teal;
		}
		&:hover,
		&:focus {
			@apply bg-teal;
		}
	}

	.chevron {
		position: absolute;
		right: 5px;
		width: 13px;
		height: 7px;

		transition: transform 300ms ease;

		pointer-events: none;
		.isOpen & {
			.chevron {
				transform: rotate(-180deg);
			}
		}
	}
}
</style>
