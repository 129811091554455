<template>
<div class="slideout-container">
	<aside class="slideout " :class="[{ active: open }, classList]">
		<button class="js-close absolute top-25 right-30 lg:right-60 general-hover" @click="handleClose()">
			<svg width="20" height="20" viewBox="0 0 20 20">
				<path d="M1 1L20 20" stroke="black" />
				<path d="M20 1L1 20" stroke="black" />
			</svg>
		</button>

		<div class="h-full px-30 sm:px-rg pb-40 pt-lg sm:pt-xl text-left">
			<slot />
		</div>
	</aside>
	<backdrop :open="open" @close="handleClose()" />
</div>
</template>

<script>
import { stopScroll, resumeScroll } from 'Utils/scrollControl';
import Backdrop from '~VComponents/Backdrop';

export default {
	name: 'SlideOut',
	components: {
		Backdrop,
	},

	props: {
		open: {
			type: Boolean,
			default: false,
		},
		wrapClass: {
			type: String,
			default: 'bg-offWhite',
		},
	},
	emits: ['close'],
	computed: {
		classList() {
			let classList = '';
			if (this.open === true) {
				classList = ` active ${this.wrapClass}`;
			} else {
				classList = this.wrapClass;
			}
			return classList;
		},
	},
	watch: {
		open(newValue) {
			if (newValue) {
				stopScroll();
			} else {
				resumeScroll();
			}
		},
	},
	methods: {
		handleClose() {
      console.log('testing here...');
			// this.open = false;
			this.$emit('close', true);
		},
	},
};
</script>

<style lang="scss">
.slideout {
	@apply fixed h-screen overflow-y-auto top-0 w-500 max-w-full;
	right: 0;
	z-index: 99;
	transform: translateX(100vw);
	transition: transform 0.3s ease;
	@screen md {
		@apply w-8/12;
		transform: translateX(75vw);
	}
	@screen lg {
		@apply w-1/2;
		transform: translateX(55vw);
	}
	&.active {
		transform: translateX(0);
	}
}
</style>
