// import { getSiblings } from 'Utils/utilities';
import { slideDown, slideUp } from 'Utils/slide';

class ClickToExpand {
	constructor(trigger, options) {
		this.triggerSelector = trigger;
		this.trigger = document.querySelectorAll(this.triggerSelector);
		this.targets = [];
		if (this.trigger === null) {
			console.error(`ClickToExpand trigger: ${trigger}, is null`);
			return;
		}

		this.trigger.forEach((item, index) => {
			const targetId = item.getAttribute('data-target');
			this.targets[index] = document.querySelector(targetId);

			this.options = options;
		});
		if (this.trigger === null) {
			console.error(`ClickToExpand target: ${targetId},  is null`);
			return;
		}
		// console.log(this.trigger, targetId)
		this.bindEvents();
	}

	handleClose(index) {
		slideUp(this.targets[index]);
	}

	handleCloseSiblings(item) {
		const wrap = item.closest(this.options.group);
		this.trigger.forEach((item) => {
			item.classList.remove('active');
		});
		this.targets.forEach((sibling, index) => {
			console.log(sibling.parentNode, wrap);
			if (sibling.closest(this.options.group) === wrap && sibling !== item) {
				slideUp(this.targets[index]);
			}
		});
	}

	bindEvents() {
		this.trigger.forEach((item, index) => {
			item.addEventListener('click', () => {
				if (this.options && this.options.group) {
					this.handleCloseSiblings(this.targets[index]);
				}
				if (item.classList.contains('active')) {
					slideUp(this.targets[index], { duration: 300 });
					item.classList.toggle('active');
				} else {
					slideDown(this.targets[index], { duration: 300 });
					item.classList.toggle('active');
				}

				// if the target has an input inside, focus on it.
				const firstInput = this.targets[index].querySelector('input');
				const close = this.targets[index].querySelector('.js-close');
				if (firstInput) {
					firstInput.focus();
				}
				if (close) {
					close.addEventListener('click', () => { this.handleClose(index); });
				}
			});
		});
	}
}

export default ClickToExpand;
