var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative", attrs: { id: "subdivide-questionnaire" } },
    [
      _c(
        "FormulateForm",
        {
          key: "form",
          ref: "form",
          staticClass: "w-full",
          on: { submit: _vm.handleSubmit }
        },
        [
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "name",
              label: "Name",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "email",
              name: "email",
              label: "Email",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "phone",
              label: "Phone",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "purpose",
              label: "Purpose",
              validation: "^required",
              value: "Subdivide to " + _vm.type,
              readonly: "true",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "address",
              label: "Address",
              validation: "^required",
              value: _vm.address,
              readonly: "true",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "bedrooms",
              label: "Bedrooms",
              value: _vm.bedrooms,
              readonly: "true",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "garage",
              label: "Garage",
              value: _vm.garage,
              readonly: "true",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "living",
              label: "Living",
              value: _vm.living,
              readonly: "true",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "budget",
              label: "Budget",
              value: _vm.budget,
              readonly: "true",
              validation: "^required",
              "outer-class": "form-group form-group--inline"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "recaptcha-blurb" }, [
            _c("p", [
              _vm._v(
                "\n\t\t\t\tThis site is protected by reCAPTCHA and the Google\n\t\t\t\t"
              ),
              _c(
                "a",
                { attrs: { href: "https://policies.google.com/privacy" } },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v("\n\t\t\t\tand\n\t\t\t\t"),
              _c(
                "a",
                { attrs: { href: "https://policies.google.com/terms" } },
                [_vm._v("Terms of Service")]
              ),
              _vm._v("\n\t\t\t\tapply.\n\t\t\t")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full flex justify-center" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn--primary uppercase mb-30 md:mt-xs min-w-150",
                class: { loading: _vm.loading },
                attrs: { disabled: _vm.loading }
              },
              [_vm._v("\n\t\t\t\tSubmit\n\t\t\t")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }