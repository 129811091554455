import axios from 'axios';
import marked from 'marked';
import ImgixClient from '@imgix/js-core';

export default {
	install(Vue) {
		const state = Vue.observable({
			// axios
			axios: undefined,
			csrfToken: undefined,
			csrfName: undefined,
			// cart
			cart: {},
			cartOpen: false,
			lineItems: [],
			couponMessage: '',
			cartError: '',
			// images
			imgix: new ImgixClient({
				domain: 'subdivide.imgix.net',
				secureURLToken: '6088e5d86c782234f5a5d01f',
			}),
			// other
			user: undefined,
			products: [],
			loading: true,
			// google recaptcha score
			gScore: 0,
		});

		const getters = {
			// api
			csrfName: () => state.csrfName,
			csrfToken: () => state.csrfToken,
			axios: () => state.axios,
			// cart
			cartItems: () => state.cartItems,
			cartOpen: () => state.cartOpen,
			cart: () => state.cart,
			cartError: () => state.cartError,
			lineItems: () => state.lineItems,
			couponMessage: () => state.couponMessage,

			// other
			loading: () => state.loading,
			gScore: () => state.gScore,
			user: () => state.user,
			imgix: () => state.imgix,
		};

		const mutations = {
			// axios
			setAxios: (v) => {
				state.axios = v;
			},
			setCsrfName: (v) => {
				state.csrfName = v;
			},
			setCsrfToken: (v) => {
				state.csrfToken = v;
				// eslint-disable-next-line no-use-before-define
				actions.initAxios();
			},
			// cart
			setCart: (v) => {
				state.cart = v;
			},
			setLineItems: (v) => {
				state.lineItems = v;
			},
			setCartOpen: (v) => {
				state.cartOpen = v;
			},
			setCartError: (v) => {
				state.cartError = v;
			},
			setCouponMessage: (v) => {
				state.couponMessage = v;
			},
			// other
			setLoading: (v) => {
				state.loading = v;
			},
			setProducts: (v) => {
				state.products = v;
			},
			setUser: (v) => {
				state.user = v;
			},
			setGScore: (v) => {
				state.gScore = v;
			},
		};

		const actions = {
			async initAxios() {
				const CraftApi = axios.create();
				CraftApi.defaults.headers.post['X-CSRF-Token'] = getters.csrfToken();
				mutations.setAxios(CraftApi);
			},
			async clearCart() {
				const clearData = new FormData();
				clearData.append('action', 'commerce/cart/update-cart');
				getters.lineItems().forEach((item) => {
					clearData.append(`lineItems[${item.id}][remove]`, 1);
				});
				console.log('clear cart');
				axios
					.post('/', clearData, {
						headers: { Accept: 'application/json' },
					})
					.then((response) => {
						console.log(response);
					});
			},
			async fetchCart() {
				mutations.setLoading(true);
				if (getters.csrfToken() === undefined) {
					actions.csrfSet();
				}
				// const token = getters.csrfToken();
				const data = new FormData();
				// data.append('CRAFT_CSRF_TOKEN', token);
				data.append('action', 'commerce/cart/get-cart');
				// //
				await getters
					.axios()
					.post('/', data)
					.then((response) => {
						// console.log(response.data);
						mutations.setLoading(false);
						mutations.setCart(response.data.cart);
					})
					.catch((error) => {
						mutations.setLoading(false);
						console.error(error);
					});
			},
			async fetchLineItems() {
				//
				await getters
					.axios()
					.get('/commerce/cart/get-line-items')
					.then((response) => {
						// s;
						mutations.setLineItems(response.data);
					})
					.catch((error) => {
						this.error = error;
						console.error(error);
					});
			},
			checkGscore() {
				if (getters.gScore() < 0.8) {
					this.error = 'You might be spam';
					this.loading = false;
					return false;
				}
				return true;
			},

			async addToCart(options) {
				const token = getters.csrfToken();
				const data = new FormData();
				data.append('action', 'commerce/cart/update-cart');
				// console.log(options.purchasableId);
				data.append('purchasableId', options.purchasableId);
				data.append('qty', options.quantity);
				data.append('CRAFT_CSRF_TOKEN', token);

				getters
					.axios()
					.post('/', data)
					.then((response) => {
						mutations.setCartOpen(true);
						if (response.data.success) {
							// console.log('success');
						} else if (!response.data.success) {
							const { message } = response.data;
							Object.keys(response.data.errors).forEach((x) => {
								message.push(
									`${message} ${response.data.errors[x][0].replace(/-/g, ' ')}`,
								);
							});
							mutations.setCartError(message);
							setTimeout(() => {
								mutations.setCartError('');
							}, 4000);
						}
					})
					.then(() => true)
					.catch((error) => {
						console.error('Error:', error);
					});
			},
			async submitDiscountCode(couponCode) {
				const token = getters.csrfToken();
				const data = new FormData();

				data.append('action', 'commerce/cart/update-cart');
				data.append('couponCode', couponCode);
				data.append('CRAFT_CSRF_TOKEN', token);

				axios
					.post('/', data, {
						headers: { Accept: 'application/json' },
					})
					.then((response) => {
						if (response.data.success) {
							if (response.data.cart.totalDiscount === 0) {
								mutations.setCouponMessage('Conditions Not Meet');
							} else {
								mutations.setCouponMessage('Discount Applied');
							}
							setTimeout(() => {
								mutations.setCouponMessage('');
							}, 4000);
							this.loadCart();
						} else if (
							response.data.errors
							&& response.data.errors.couponCode.length
						) {
							mutations.setCouponMessage(response.data.errors.couponCode[0]);
						}
					});
			},
			async updateQty(id, qty) {
				const token = getters.csrfToken();
				mutations.setLoading(true);
				const data = new FormData();
				data.append('CRAFT_CSRF_TOKEN', token);
				data.append('action', 'commerce/cart/update-cart');
				data.append(`lineItems[${id}][qty]`, qty);

				// needs to go one after the other async or sync
				axios
					.post('/', data, {
						headers: { Accept: 'application/json' },
					})
					.then((response) => {
						if (!response.data.success) {
							let { message } = response.data;
							Object.keys(response.data.errors).forEach((x) => {
								message = `${message} ${response.data.errors[x][0].replace(
									/-/g,
									' ',
								)}`;
							});
							mutations.setCartError(message);
							setTimeout(() => {
								mutations.setCartError('');
							}, 4000);
						} else {
							actions.fetchLineItems();
							actions.fetchCart();
						}
						mutations.setLoading(false);
					})
					.catch((error) => {
						// this.error = error;
						console.error(error);
					});
			},
			formatMoney(amount) {
				const formatter = new Intl.NumberFormat('en-NZ', {
					style: 'currency',
					currency: 'NZD',
				});

				return formatter.format(amount);
			},
			csrfSet() {
				if (getters.csrfToken() === undefined) {
					mutations.setCsrfName(window.csrfName);
					mutations.setCsrfToken(window.csrfToken);
				}
			},
			async userInit() {
				if (getters.user() === undefined) {
					mutations.setUser(window.user);
				}
			},
			handelize(str) {
				return str
					.replace('& ', '')
					.replace(/\s/g, '-')
					.toLowerCase();
			},
			imgixImg(img, options = { auto: 'compress' }) {
				const newImg = img;
				// remove image path
				if (window.location.hostname.includes('test')) {
					newImg.title = img.title;
					newImg.url = img.url;
					newImg.small = img.url;
				} else {
					newImg.title = img.title;
					newImg.url = img.url;
					newImg.small = img.url;
					if (img.path) {
						newImg.url = state.imgix.buildURL(newImg.path, options);
					} else {
						const newUrl = img.url
							.replace(window.location.hostname, '')
							.replace(window.location.protocol, '')
							.replace('//images', 'images');
						newImg.url = state.imgix.buildURL(newUrl, options);
					}
					newImg.small = state.imgix.buildURL(newImg.url, { q: 10 });
				}

				return newImg;
			},
			marked(val, options) {
				if (options && options.inline === true) {
					return marked.parseInline(val);
				}
				return marked(val);
			},
			async graphQlQuery(qObject) {
				return axios('/api', {
					method: 'post',
					data: {
						query: qObject,
					},
				})
					.then((response) => {
						if (response.data.errors) {
							console.error(response.data.errors);
							return false;
						}
						// console.log(response);
						return response.data;
					})
					.catch((error) => {
						console.error(error);
					});
			},
		};
		Vue.prototype.$store = { getters, mutations, actions };
	},
};
