var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-dropdown", class: { isOpen: _vm.isOpen } },
    [
      _c(
        "div",
        {
          staticClass: "selected relative",
          class: _vm.backgroundColor,
          on: { click: _vm.toggleIsOpen }
        },
        [
          _vm._v(
            "\n\t\t" + _vm._s(_vm.selected ? _vm.selected.title : "") + "\n\t\t"
          ),
          _c("div", { staticClass: "chevron-wrap center-y right-5" }, [
            _c(
              "svg",
              {
                staticClass: "chevron ",
                attrs: { viewBox: "0 0 9 5", fill: "none" }
              },
              [
                _c("path", {
                  attrs: { d: "M1 1L4.5 4L8 1", stroke: "currentColor" }
                })
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          ref: "list",
          staticClass: "vue-dropdown__option-list",
          class: { isOpen: _vm.isOpen }
        },
        _vm._l(_vm.options, function(o, i) {
          return _c(
            "li",
            {
              key: i,
              class: {
                active:
                  (_vm.selected && _vm.selected === o.handle) ||
                  _vm.selected === o
              },
              on: {
                click: function($event) {
                  return _vm.handleClick(o)
                }
              }
            },
            [_vm._v("\n\t\t\t" + _vm._s(o.title ? o.title : o) + "\n\t\t")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }