import { stopScroll, resumeScroll } from 'Utils/scrollControl';
import debounce from 'lodash/debounce';

const navTrigger = (function () {
	let MenuTrigger;
	let mainNav;
	let state = 'closed';
	let animating = false;

	const handleAnimation = () => {
		animating = true;
		mainNav.classList.add('is-animating');
		setTimeout(() => {
			animating = false;
			mainNav.classList.remove('is-animating');
		}, 500);
	};
	const bindElements = () => {
		mainNav = document.getElementById('main-nav');
		if (mainNav !== undefined && mainNav !== null) {
			MenuTrigger = document.querySelectorAll('.main-nav-trigger');
		} else {
			console.warn('Cant find Nav');
		}
	};
	const handleClose = debounce(
		() => {
			if (state === 'open') {
				resumeScroll();
				handleAnimation();

				mainNav.classList.remove('is-open');
				MenuTrigger.forEach((trigger) => {
					trigger.setAttribute('aria-expanded', 'false');
					trigger.classList.remove('is-active');
				});

				setTimeout(() => {
					state = 'closed';
				}, 200);
			}
		},
		200,
		{ leading: true, trailing: false },
	);
	const handleOpen = () => {
		if (state === 'closed') {
			handleAnimation();
			mainNav.classList.add('is-open');
			stopScroll();
			MenuTrigger.forEach((trigger) => {
				trigger.setAttribute('aria-expanded', 'true');
				trigger.classList.add('is-active');
			});

			setTimeout(() => {
				state = 'open';
				document.addEventListener(
					'click',
					() => {
						handleClose();
					},
					{ once: true },
				);
			}, 200);
		}
	};

	const toggleMenu = () => {
		if (!animating) {
			if (state === 'closed') {
				handleOpen();
			} else if (state === 'open') {
				handleClose();
			}
		}
	};
	const init = () => {
		bindElements();
		if (mainNav !== undefined && mainNav !== null) {
			MenuTrigger.forEach((trigger) => {
				trigger.addEventListener('click', toggleMenu);
			});
		}
	};
	return {
		init,
	};
}());

export { navTrigger };
