var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.autocompleteText,
        expression: "autocompleteText"
      }
    ],
    ref: "autocomplete",
    class: _vm.classname,
    attrs: {
      id: _vm.id,
      type: "text",
      placeholder: _vm.placeholder,
      disabled: _vm.disabled
    },
    domProps: { value: _vm.autocompleteText },
    on: {
      focus: function($event) {
        return _vm.onFocus()
      },
      blur: function($event) {
        return _vm.onBlur()
      },
      change: _vm.onChange,
      keypress: _vm.onKeyPress,
      keyup: _vm.onKeyUp,
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.autocompleteText = $event.target.value
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }