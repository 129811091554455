<template>
<div class="row items-center justify-around">
	<div
		class="col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-5"
	>
		<slot name="bedrooms" />
		<Dropdown
			:options="bedrooms"
			@selected="setQuestionnaireValue($event, 'subdivide-bedrooms')"
		/>
	</div>
	<div
		class="col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-4"
	>
		<slot name="living" />
		<Dropdown
			:options="livingRooms"
			@selected="setQuestionnaireValue($event, 'subdivide-livingRooms')"
		/>
	</div>
	<div
		class="col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-3"
	>
		<slot name="garage" />
		<Dropdown
			:options="garage"
			@selected="setQuestionnaireValue($event, 'subdivide-garage')"
		/>
	</div>
	<div
		class="col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-2"
	>
		<slot name="budget" />
		<Dropdown
			:options="approxBudget"
			@selected="setQuestionnaireValue($event, 'subdivide-budget')"
		/>
	</div>
</div>
</template>

<script>
import Dropdown from '~VComponents/Dropdown';

export default {
	name: 'QuestionnaireDropdowns',
	components: {
		Dropdown,
	},
	props: {
		approxBudget: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			success: false,
			bedrooms: [
				{ title: '2', handle: '2' },
				{ title: '3', handle: '3' },
				{ title: '4', handle: '4' },
				{ title: '5', handle: '5' },
				{ title: '6', handle: '6' },
			],
			livingRooms: [
				{ title: '1', handle: '1' },
				{ title: '2', handle: '2' },
				{ title: '3', handle: '3' },
				{ title: '4', handle: '4' },
				{ title: '5', handle: '5' },
				{ title: '6', handle: '6' },
			],
			garage: [{ title: 'Yes', handle: 'yes' }, { title: 'No', handle: 'no' }],
		};
	},
	created() {
		if (!window.sessionStorage.getItem('subdivide-bedrooms')) {
			this.setQuestionnaireValue(this.bedrooms[0], 'subdivide-bedrooms');
		}
		if (!window.sessionStorage.getItem('subdivide-livingRooms')) {
			this.setQuestionnaireValue(this.livingRooms[0], 'subdivide-livingRooms');
		}
		if (!window.sessionStorage.getItem('subdivide-garage')) {
			this.setQuestionnaireValue(this.garage[0], 'subdivide-garage');
		}
		if (!window.sessionStorage.getItem('subdivide-budget')) {
			this.setQuestionnaireValue(this.approxBudget[0], 'subdivide-budget');
		}
	},
	methods: {
		setQuestionnaireValue(event, val) {
			sessionStorage.setItem(val, event.handle);
		},
	},
};
</script>
