<template>
<div
	class="backdrop"
	:class="[{ close: !open }, { open: open }]"
	@click="handleClose"
/>
</template>

<script>
export default {
	name: 'Backdrop',
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['close'],
	// data: {
	// 	open: false,
	// },
	methods: {
		handleClose() {
			// this.open = false;
			this.$emit('close', true);
		},
	},
};
</script>

<style scoped lang="scss">
.backdrop {
	z-index: 50;
	pointer-events: none;
	height: 100vh;
	min-height: -webkit-fill-available;
	width: 100%;
	@apply fixed opacity-60 top-0 left-0 bg-navy bg-opacity-50;
	&.open {
		pointer-events: auto;
		animation: fade-in 0.3s ease both;
	}
	&.close {
		animation: fade-out 0.3s ease both;
	}
}
</style>
