<template>
<div class="form-group form-group--styled site-assesment mb-30 relative">
	<GoogleAutocomplete
		id="autoComplete"
		class="mb-sm md:mb-0"
		name="address"
		placeholder="Enter the address you would like to subdivide"
		country="nz"
		:fields="options"
		@placechanged="setSubdivideAddress"
	/>
	<slot :formatted-address="formattedAddress" />
</div>
</template>

<script>
import GoogleAutocomplete from '~VModules/GoogleAutocomplete';

export default {
	name: 'SimpleAddressLookup',
	components: {
		GoogleAutocomplete,
	},
	props: {
		successMessage: {
			type: String,
			default: "We'll be in touch soon",
		},
	},
	emits: [],
	data() {
		return {
			success: false,
			options: [
				'address_components',
				'formatted_address',
				'geometry',
				'name',
				'vicinity',
			],
			address: undefined,
			place: undefined,
			formattedAddress: undefined,
		};
	},

	methods: {
		noResults(e) {
			console.log(e);
		},
		setSubdivideAddress(addressData, place) {
			this.place = place;
			this.address = addressData;
			this.formattedAddress = `${this.place.name}, ${this.place.vicinity}`;
			window.sessionStorage.setItem('subdivide-address', this.formattedAddress);
			window.sessionStorage.setItem('subdivide-lng', this.address.longitude);
			window.sessionStorage.setItem('subdivide-lat', this.address.latitude);
			console.log(addressData, place);
		},
	},
};
</script>
