var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row items-center justify-around" }, [
    _c(
      "div",
      {
        staticClass:
          "col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-5"
      },
      [
        _vm._t("bedrooms"),
        _vm._v(" "),
        _c("Dropdown", {
          attrs: { options: _vm.bedrooms },
          on: {
            selected: function($event) {
              return _vm.setQuestionnaireValue($event, "subdivide-bedrooms")
            }
          }
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-4"
      },
      [
        _vm._t("living"),
        _vm._v(" "),
        _c("Dropdown", {
          attrs: { options: _vm.livingRooms },
          on: {
            selected: function($event) {
              return _vm.setQuestionnaireValue($event, "subdivide-livingRooms")
            }
          }
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-3"
      },
      [
        _vm._t("garage"),
        _vm._v(" "),
        _c("Dropdown", {
          attrs: { options: _vm.garage },
          on: {
            selected: function($event) {
              return _vm.setQuestionnaireValue($event, "subdivide-garage")
            }
          }
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "col w-full xs:w-1/2 md:w-auto flex flex-col items-center mb-40 z-2"
      },
      [
        _vm._t("budget"),
        _vm._v(" "),
        _c("Dropdown", {
          attrs: { options: _vm.approxBudget },
          on: {
            selected: function($event) {
              return _vm.setQuestionnaireValue($event, "subdivide-budget")
            }
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }