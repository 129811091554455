var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group form-group--styled site-assesment mb-30 relative"
    },
    [
      _c("GoogleAutocomplete", {
        staticClass: "mb-sm md:mb-0",
        attrs: {
          id: "autoComplete",
          name: "address",
          placeholder: "Enter the address you would like to subdivide",
          country: "nz",
          fields: _vm.options
        },
        on: { placechanged: _vm.setSubdivideAddress }
      }),
      _vm._v(" "),
      _vm._t("default", null, { formattedAddress: _vm.formattedAddress })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }