var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { __open: _vm.isPopupOpen },
      attrs: { id: "QuestionerRedirectModel" }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "__model-wrapper" }, [
      _c("div", { staticClass: "model-title" }, [
        _c("h1", { staticClass: "h1 mb-md styled-heading" }, [
          _vm._v("Welcome to "),
          _c("em", [_vm._v("Subdivide")])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "model-content" }, [
        _c("p", { staticClass: "text-h5" }, [
          _vm._v("What options do I have to subdivide my property ? "),
          _c("br"),
          _vm._v(
            "Click the button below to unlock your free feasibility report."
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "action-button" }, [
        _c(
          "a",
          {
            staticClass:
              "btn btn--primary max-w-none max-w-none whitespace-no-wrap",
            attrs: { href: "/questionnaire/subdivide-questionnaire" }
          },
          [_vm._v("\n        Request A Free Report\n      ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }