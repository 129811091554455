<template>
<div id="subdivide-questionnaire" class="relative">
	<FormulateForm
		ref="form"
		key="form"
		class="w-full"
		@submit="handleSubmit"
	>
		<FormulateInput
			type="text"
			name="name"
			label="Name"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>
		<FormulateInput
			type="email"
			name="email"
			label="Email"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>
		<FormulateInput
			type="text"
			name="phone"
			label="Phone"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>
		<FormulateInput
			type="text"
			name="purpose"
			label="Purpose"
			validation="^required"
			:value="`Subdivide to ${type}`"
			readonly="true"
			outer-class="form-group form-group--inline"
		/>
		<FormulateInput
			type="text"
			name="address"
			label="Address"
			validation="^required"
			:value="address"
			readonly="true"
			outer-class="form-group form-group--inline"
		/>

		<FormulateInput
			type="text"
			name="bedrooms"
			label="Bedrooms"
			:value="bedrooms"
			readonly="true"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>
		<FormulateInput
			type="text"
			name="garage"
			label="Garage"
			:value="garage"
			readonly="true"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>
		<FormulateInput
			type="text"
			name="living"
			label="Living"
			:value="living"
			readonly="true"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>

		<FormulateInput
			type="text"
			name="budget"
			label="Budget"
			:value="budget"
			readonly="true"
			validation="^required"
			outer-class="form-group form-group--inline"
		/>
		<div class="recaptcha-blurb">
			<p>
				This site is protected by reCAPTCHA and the Google
				<a href="https://policies.google.com/privacy">Privacy Policy</a>
				and
				<a href="https://policies.google.com/terms">Terms of Service</a>
				apply.
			</p>
		</div>
		<div class="w-full flex justify-center">
			<button
				class="btn btn--primary uppercase mb-30 md:mt-xs min-w-150"
				:class="{ loading: loading }"
				:disabled="loading"
			>
				Submit
			</button>
		</div>
	</FormulateForm>
</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'SiteAssessmentForm',

	props: {
		successMessage: {
			type: String,
			default: "We'll be in touch soon",
		},
	},
	emits: [],
	data() {
		return {
			success: false,
			loading: false,
			address: '',
			bedrooms: '',
			living: '',
			garage: '',
			budget: '',
			type: '',
			expressForm: undefined,
		};
	},
	created() {
		this.address = window.sessionStorage.getItem('subdivide-address');
		this.type = window.sessionStorage.getItem('subdivide-type');
		this.bedrooms = window.sessionStorage.getItem('subdivide-bedrooms');
		this.living = window.sessionStorage.getItem('subdivide-livingRooms');
		this.garage = window.sessionStorage.getItem('subdivide-garage');
		this.budget = window.sessionStorage.getItem('subdivide-budget');
	},
	mounted() {
		this.expressForm = document.getElementById(
			'express-subdivide-questionnaire',
		);
	},
	methods: {
		handleSubmit(event) {
			this.loading = true;
			if (!this.$store.actions.checkGscore()) {
				return false;
			}

			const formId = this.expressForm.formId.value;
			const formPayload = this.expressForm.formPayload.value;
			const data = new FormData(this.$refs.form.$el);
			data.append(window.csrfName, window.csrfToken);
			data.append('formId', formId);
			data.append('formPayload', formPayload);

			axios
				.post('/actions/express-forms/submit', data, {
					headers: {
						'Cache-Control': 'no-cache',
						'X-Requested-With': 'XMLHttpRequest',
						HTTP_X_REQUESTED_WITH: 'XMLHttpRequest',
					},
				})
				.then(response => response.data)
				.then((data) => {
					if (data.success) {
						this.success = true;
						window.swup.loadPage({
							url: '/questionnaire/confirmation',
						});
					} else if (data.errors) {
						this.error = data.errors;
					}
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
};
</script>
