import throttle from 'lodash/throttle';

const stickyNav = (() => {
	let mainNav;
	let animating = false;
	let navPos;

	const setSticky = () => {
		animating = true;

		mainNav.classList.add('nav-sticky--hide', 'nav-sticky');

		setTimeout(() => {
			mainNav.classList.add('nav-sticky--show');
			mainNav.classList.remove('nav-sticky--hide');
			animating = false;
		}, 200);
	};

	const removeSticky = () => {
		animating = true;

		mainNav.classList.add('nav-sticky--hide');

		setTimeout(() => {
			mainNav.classList.remove(
				'nav-sticky--hide',
				'nav-sticky',
				'nav-sticky--show',
			);

			animating = false;
		}, 200);
	};

	const handleSticky = throttle(
		() => {
			const currentNav = document.getElementById('main-nav');
			if (
				window.scrollY >= window.innerHeight * 1.25
				&& mainNav.matches('.nav-sticky') === false
				&& !animating
			) {
				// console.log('Set Nav Sticky ');
				setSticky();
			} else if (
				window.scrollY <= window.innerHeight * 1.25
				&& mainNav.matches('.nav-sticky')
				&& !currentNav.classList.contains('is-open')
				&& !animating
			) {
				removeSticky();
			}
		},
		200,
		{ leading: true },
	);

	const alwaysSticky = throttle(
		() => {
			if (
				window.scrollY >= navPos
				&& mainNav.matches('.nav-sticky') === false
				&& !animating
			) {
				mainNav.classList.add('nav-sticky--show', 'nav-sticky');
			} else if (
				window.scrollY <= navPos
				&& mainNav.matches('.nav-sticky')
				&& !animating
			) {
				mainNav.classList.remove('nav-sticky--show', 'nav-sticky');
			}
		},
		200,
		{ leading: true },
	);

	const init = () => {
		mainNav = document.getElementById('main-nav');

		if (mainNav !== undefined && mainNav !== null) {
			navPos = mainNav.getBoundingClientRect().y;
			const sticky = mainNav.getAttribute('data-sticky');
			if (sticky !== 'always') {
				window.addEventListener('scroll', handleSticky);
			}
		} else {
			console.warn('Sticky nav cant find nav');
		}
	};

	return {
		init,
	};
})();

export { stickyNav };
