<template>
<div id="TestimonialsCarousel" class="swiper--fade">
	<Swiper ref="swiper" type="carousel" :options="swiperOptions">
		<SwiperSlide v-for="(slide, i) in slides" :key="i">
			<div class="h-full">
				<p
					class="h4 text-white font-kilm mb-40 "
					v-html="`${marked(slide.text, { inline: true })}`"
				/>
				<p class="text-lead text-white ">{{ slide.person }}</p>
			</div>
		</SwiperSlide>
	</Swiper>
	<div class="swiper-pagination w-full">
		<span
			v-for="(slide, i) in slides"
			:key="`dot-${i}`"
			:class="{ active: i === activeIndex }"
			class="swiper-pagination-bullet"
			@click="updateSwiper(i)"
		/>
	</div>
</div>
</template>

<script>
import {
	Swiper as SwiperClass,
	Autoplay,
	EffectFade,
	Pagination,
} from 'swiper/core';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

SwiperClass.use([Autoplay, EffectFade, Pagination]);

export default {
	name: 'TestimonialCarousel',
	components: {
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: {
		slides: {
			type: Array,
			required: true,
		},
	},
	emits: [],
	data() {
		return {
			activeIndex: 0,
			swiper: null,
			swiperOptions: {
				effect: 'fade',
				fadeEffect: { crossFade: true },
				loop: true,
				loopedSlides: this.slides.length,
				slidesPerView: 1,
				spaceBetween: 0,
			},
			mounted: false,
			autoplay: undefined,
			autoplayTimer: 7000,
		};
	},
	mounted() {
		this.swiper = this.$refs.swiper.swiperInstance;
		this.autoplay = setInterval(this.swiperAutoplayFix, this.autoplayTimer);
	},
	methods: {
		swiperAutoplayFix() {
			if (this.activeIndex >= this.slides.length - 1) {
				this.activeIndex = 0;
				this.swiper.updateActiveIndex(0);
			} else {
				this.activeIndex += 1;
				this.swiper.updateActiveIndex(this.activeIndex);
			}
		},
		marked(val, type) {
			return this.$store.actions.marked(val, type);
		},
		updateSwiper(i) {
			clearInterval(this.autoplay);
			this.swiper.updateActiveIndex(i);
			this.swiper = this.$refs.swiper.swiperInstance;
			this.activeIndex = this.$refs.swiper.swiperInstance.activeIndex;
			this.autoplay = setInterval(this.swiperAutoplayFix, this.autoplayTimer);
		},
	},
};
</script>
<style lang="scss">
@import 'swiper/swiper';

#TestimonialsCarosusel {
	em {
		font-style: normal;
		position: relative;
		@apply inline-block;
		&:after {
			content: '';
			height: 1px;
			bottom: -2px;
			left: 0;
			@apply bg-navy w-full absolute;
		}
	}
}

.swiper-slide {
	@apply flex justify-center py-15 opacity-0;
	height: auto !important;
	transition: opacity 0.5s ease !important ;
}
.swiper-slide-active {
	transition: opacity 0.5s 0.5s ease !important ;
}
.swiper-container {
	@apply pb-15;
}
.swiper-pagination {
	@apply flex justify-center;
}
.swiper-pagination-bullet {
	@apply bg-white transition-colors duration-200;
	width: 10px;
	height: 10px;
	display: block;
	background: #fff;
	border-radius: 100%;
	margin-right: 5px;
	&.active,
	&:hover {
		@apply bg-teal cursor-pointer;
	}
}
</style>
