<template>
<div id="SiteAssessmentFormSlideout">
	<SlideOut :open="open" @close="open = false">
		<div class="w-full pb-30">
			<h1
				class="h1 styled-heading mb-30"
				v-html="
					$store.actions.marked('*Free* Site Assessment', { inline: true })
				"
			/>
			<div class="mardown mb-30" v-html="$store.actions.marked(text)" />
			<SiteAssessmentForm :success-message="successMessage" />
		</div>
	</SlideOut>
	<Backdrop :open="open" @close="open = false" />
</div>
</template>

<script>
import Backdrop from '~VComponents/Backdrop';
import SlideOut from '~VComponents/SlideOut';
import SiteAssessmentForm from '~VForms/SiteAssessmentForm';

export default {
	name: 'SiteAssessmentFormSlideout',
	components: {
		Backdrop,
		SiteAssessmentForm,
		SlideOut,
	},
	props: {
		successMessage: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			open: false,
		};
	},
	mounted() {
		const triggers = document.querySelectorAll("[href='#free-assessment']");
		const self = this;
		triggers.forEach((item) => {
			item.addEventListener('click', (e) => {
				e.stopPropagation();
				self.open = true;
			});
		});
	},
};
</script>
