import '../scss/tailwind/tailwind-base.scss';
import '../scss/custom.scss';
import '../scss/tailwind/tailwind-utilities.scss';

import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';

import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import Store from 'Store/store';
// import GLightbox from 'glightbox';
import lazySizes from 'lazysizes';
// other imports ...
import setLabelPosition from 'Utils/setLabelPosition';

// import MainNavDropdowns from 'Modules/nav/navDropdowns';
import { navTrigger } from 'Modules/nav/navTrigger';
import { stickyNav } from 'Modules/nav/navSticky';
import ClickToExpand from 'Modules/ClickToExpand';
import MapWithMarker from 'Modules/MapWithMarker';
import SmoothReflow from '~VComponents/SmoothReflow';
import ContactForm from '~VForms/ContactForm';

import TestimonialCarousel from '~VModules/TestimonialCarousel';
import SiteAssessmentSlideout from '~VModules/SiteAssessmentSlideout';
import SimpleAddressLookup from '~VForms/SimpleAddressLookup';
import SiteAssessmentForm from '~VForms/SiteAssessmentForm';
import QuestionerRedirectModel from "./vue/modules/QuestionerRedirectModel";

import SubdivideQuestionnaire from '~VForms/SubdivideQuestionnaire';

import SubdivideTypes from '~VComponents/questionnaire/SubdivideTypes';
import QuestionnaireValues from '~VComponents/questionnaire/QuestionnaireValues';
import QuestionnaireDropdowns from '~VComponents/questionnaire/QuestionnaireDropdowns';

// stop lazy sizes from default initialising
lazySizes.cfg.init = false;

window.swup = new Swup({
	linkSelector:
		'a:not([data-no-swup]):not([target="_blank"]):not([class*="glightbox"]):not([href*="addevent"])',
	animationSelector: '[class*="swup-transition-"]',
	animateHistoryBrowsing: true,
	plugins: [
		new SwupScrollPlugin({
			doScrollingRightAway: false,
			animateScroll: true,
		}),
	],
});
const main = async () => {
	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty('--app-height', `${window.innerHeight}px`);
	};
	window.addEventListener('resize', appHeight);
	appHeight();
	// open external links in new tab
	// run if there is a 200 response from Google
	window.recaptcha_callback = function (response) {
		Vue.prototype.$store.mutations.setGScore(response.score);
	};
	// run if the user passes the captcha
	window.recaptcha_success = function (response) {
		Vue.prototype.$store.mutations.setGScore(response.score);
	};
	// run if the user fails the captcha
	window.recaptcha_failure = function (response) {
		Vue.prototype.$store.mutations.setGScore(response.score);
	};

	// eslint-disable-next-line no-unused-vars
	// initialise lazy sizes on load
	lazySizes.init();
	setLabelPosition('.form-group--styled');
	MapWithMarker();
	navTrigger.init();
	stickyNav.init();
	const faqs = new ClickToExpand('.expander__toggle');
};

const initVue = async () => {
	Vue.component('SmoothReflow', SmoothReflow);
	Vue.use(Store);
	Vue.use(VueFormulate, {
		classes: {
			outer: (context, classes) => classes.concat([
				'form-group form-group--formulate',
				context.type !== 'radio' && context.type !== 'checkbox'
					? 'form-group--styled'
					: `form-group--${context.type}`,
			]),
		},
	});

	class InitVueComponent {
		constructor(selector, component) {
			this.selector = selector;
			this.components = component;
			this.instance = undefined;
			this.initialize();
		}

		initialize() {
			const component = this.components;
			const vueEl = document.querySelector(this.selector);
			if (vueEl) {
				this.instance = new Vue({
					el: this.selector,
					delimiters: ['${', '}}'],
					components: component,
					mounted() {
						this.$store.actions.csrfSet();
						main();

						document.querySelectorAll('.lazyloading').forEach((img) => {
							setTimeout(() => {
								if (img.classList.contains('lazyloading')) {
									img.classList.add('lazyloaded');
									img.classList.remove('lazyloading');
								}
							}, 200);
						});
						document
							.querySelectorAll(
								'a:not([data-no-swup]):not([target="_blank"]):not([class*="glightbox"]):not([class^="#"])',
							)
							.forEach((link) => {
								const href = link.getAttribute('href');
								const containsHost = href.search(`${window.location.hostname}`);
								const NotLocal =									href.charAt(0) !== '/' && href.charAt(0) !== '#';
								// console.log(NotLocal);
								if (containsHost === -1 && NotLocal) {
									link.setAttribute('target', '_blank');
								}
							});
						window.swup.on('willReplaceContent', () => {
							this.$destroy();
						});
					},
				});
			}
		}
	}
	const VueContainer = new InitVueComponent('#vue-container', {
		QuestionnaireValues,
		ContactForm,
		QuestionnaireDropdowns,
		SimpleAddressLookup,
		SiteAssessmentForm,
		SiteAssessmentSlideout,
		SubdivideQuestionnaire,
		SubdivideTypes,
		TestimonialCarousel,
		QuestionerRedirectModel,
	});
};

initVue();
window.swup.on('animationInStart', () => {
	lazySizes.init();
	initVue();
});
