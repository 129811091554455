import * as mapStyles from 'Utils/mapStyles';

export default () => {
	const contactMap = document.getElementById('googleMap');
	if (contactMap) {
		const mapLat = Number(contactMap.getAttribute('data-lat'));
		const mapLng = Number(contactMap.getAttribute('data-lng'));
		const contactGoogleMap = new google.maps.Map(contactMap, {
			center: { lat: mapLat, lng: mapLng },
			zoom: 14,
			disableDefaultUI: true,
			draggable: true,
			scrollwheel: false,
			panControl: true,
			styles: mapStyles,
		});
		const marker = `${window.location.origin}/icons/mapMarker.png`;
		new google.maps.Marker({
			position: { lat: mapLat, lng: mapLng },
			map: contactGoogleMap,
			icon: marker,
		});
	}
};
