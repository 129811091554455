var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-lg relative", attrs: { id: "site-assesment" } },
    [
      _c(
        "SmoothReflow",
        { staticClass: "relative" },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              !_vm.success
                ? _c(
                    "FormulateForm",
                    {
                      key: "form",
                      ref: "form",
                      staticClass: "w-full",
                      on: { submit: _vm.handleSubmit }
                    },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          type: "text",
                          name: "name",
                          label: "Name",
                          validation: "^required"
                        }
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "email",
                          name: "email",
                          label: "Email",
                          validation: "^required"
                        }
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "text",
                          name: "phone",
                          label: "Phone",
                          validation: "^required"
                        }
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "textarea",
                          name: "message",
                          label: "Message",
                          validation: "^required"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "recaptcha-blurb" }, [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t\tThis site is protected by reCAPTCHA and the Google\n\t\t\t\t\t\t"
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://policies.google.com/privacy"
                              }
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                          _vm._v("\n\t\t\t\t\t\tand\n\t\t\t\t\t\t"),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://policies.google.com/terms"
                              }
                            },
                            [_vm._v("Terms of Service")]
                          ),
                          _vm._v("\n\t\t\t\t\t\tapply.\n\t\t\t\t\t")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--primary uppercase mt-30",
                          class: { loading: _vm.loading },
                          attrs: { disabled: _vm.loading }
                        },
                        [_vm._v("\n\t\t\t\t\tSubmit\n\t\t\t\t")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.success
                ? _c("div", { key: "thanks", staticClass: "w-full pt-md" }, [
                    _c("h1", { staticClass: "h1 text-white" }, [
                      _vm._v(_vm._s(_vm.successMessage))
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }