var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swiper--fade", attrs: { id: "TestimonialsCarousel" } },
    [
      _c(
        "Swiper",
        {
          ref: "swiper",
          attrs: { type: "carousel", options: _vm.swiperOptions }
        },
        _vm._l(_vm.slides, function(slide, i) {
          return _c("SwiperSlide", { key: i }, [
            _c("div", { staticClass: "h-full" }, [
              _c("p", {
                staticClass: "h4 text-white font-kilm mb-40 ",
                domProps: {
                  innerHTML: _vm._s(
                    "" + _vm.marked(slide.text, { inline: true })
                  )
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-lead text-white " }, [
                _vm._v(_vm._s(slide.person))
              ])
            ])
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "swiper-pagination w-full" },
        _vm._l(_vm.slides, function(slide, i) {
          return _c("span", {
            key: "dot-" + i,
            staticClass: "swiper-pagination-bullet",
            class: { active: i === _vm.activeIndex },
            on: {
              click: function($event) {
                return _vm.updateSwiper(i)
              }
            }
          })
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }